import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SharedDataService {

  UrlDiscovery: string = 'https://discover.businesswideweb.net/httpHandler/Discovery.ashx';
  UrlTicketsServerHttp: string = 'https://tickets.businesswideweb.net/httpHandler/TicketProcess.ashx';
  UrlTicketsServerWS: string = 'wss://tickets.businesswideweb.net/httpHandler/TicketProcess.ashx';
  OnlineOfficeEditorURL: string = 'https://documents.businesswideweb.net';

  webSocketResponse: string[] = [];
  deviceGuid: string = '';
  portalGuid: string = '';
  bussinessSuiteVersion = '';
  sessionKey: string = '';
  communicationKey: string = '';
  viewCounts = [];
  viewVersions = [];
  queryTop: number = 50;
  rowsPerPage: number = 25;
  validPassword: boolean = false;
  translations = [];
  spellcheckLang = "";
  defaultFontEditor = "";
  spellcheckLocalization = "";
  portalUserGUID = "";
  PortalName = "";
  portalUserID = "";
  isCheckedIn = "";
  portalUserDisplayName = '';
  appFilters = [];
  appURL: string = '';

  minWidthPreview: number = 474;//px
  minWidthCardListColumn: number = 350;// minWidthCardListColumn + minWidthPreview + MenuWidth(200) should be less than 1024px-6px
  minWidthTableView: number = 350;
  menuWidth: number = 200;  
  menuWidthTabletMode: number = 50;  
  maxWidthPopup:number=1100;
  minHeightFroala:number=100;
  
  params: any;
  channel: string;
  disableListboard:boolean;
  displayUpdatingWindow:boolean=false;
  loadingText:string;
  pleaseWaitText:string;
  updatingAppText:string;
  progressDownloading:string;
  boGUIDPlugin:any;
  currentInlinePreview:any={};
  showCardDetail:boolean=true;
  queryTopTreeMode: number = 100000;
  GUIDDocumentsListboard:string='aa208582-b5e1-40de-a367-6ad4cbc0bb76';
  userSettings:any[]=[];
  listEditItemPopUp:any[]=[];
  listCreateDocumentWizardPopUp:any[]=[];
  listCardDetailPopUp: Array<any> = [];
  listCardListModalPopUp:any[]=[];
  listFilterListPopUp:any[]=[];
  currentTooltip:any={};
  GUIDWikiComplete='7f45ff4b-f553-4e1c-9496-28837dd3519f';
  DefaultTableView:string='DefaultTableView';
  DefaultListView:string='DefaultListView';
  currentPortal:any;
  currentPortalTmp:any;
  listPortals:any[]=[];
  queryTopCalendarMobileMode:number=5000;
  showingConfigurateSecurity:boolean=false;
  biometricAccessEnabled:boolean=false;
  listMobileSettingsOptions:any[]=[];
  disableOnResumeSubscription:boolean=false;
  contextMenu:any;
  currentContextMenu:any;
  captionKeyPrefix:string="Mobile_";
  currentBiometricError:any={};
  showBiometricMessageScreen:boolean=false;
  biometryLockedOutMessage="Biometry is locked out.";
  biometryPasscodeLockedOutMessage="Biometry is not available in passcode lockout.";
  biometryDeniedByUserMessage="User has denied the use of biometry for this app.";
  openedNativeSettings:boolean=false;
  queryTopDimentions=100000;
  GUIDTemplatePlugin="ba382009-c57a-4a57-a335-431164b74c8e";
  captionKeyCheckInError="CheckInAlreadyRegistered";
  captionKeyCheckOutError="CheckOutAlreadyRegistered";
  WebFormPluginGUID="7e6fb048-6e25-4ab3-9191-b0f2d487a085";
  CommentObjectGUID="e27336cb-d71d-4fc0-9bc8-22e1ba86f702";
  AnswerObjectGUID="96037eb0-8fd5-4a41-a227-4434f587d5b7";
  DocumentObjectGUID="d7cd96a8-bd8c-484f-a0ea-35bd005f9ab8";
  GUIDPersonnelListboard="7ef90f63-5e42-4ecf-bee2-0895f1dd2dc8";
  haveCommentCreatePermission:boolean=false;
  ServiceTimeObjectGUID="2fe367fb-b24a-4b74-b303-79a998bb33ab";
  IdentifierGUID="bb649cc9-0a13-4a78-9b3d-f13a2aff8e5b";
  URLNormalizeCSS:any;
  URLPreviewCSS:any;
  URLHighlightCSS:any;
  URLHighlightJS:any;
  URLPreviewJS:any;
  currentContextMenuFroalaToolbar:any;
  constructor() {

  }

}
